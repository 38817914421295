import React from "react"
import Link from "./link"
import PropTypes from "prop-types"
import styled from "styled-components"
import { theme } from "./css"

import stampSVG from "../images/stamp-white.svg"
import hamburgerSVG from "../images/hamburger-menu.svg"

const navLinks = [
  // { title: `Alumni`, path: `/alumni` },
  { title: `Home`, path: `/` },
  { title: `Events`, path: `/events` },
  { title: `Timeline`, path: `/timeline` },
  { title: `Stories`, path: `/stories` },
  { title: `Give`, path: `https://giving.fresno.edu` },
]

const toggleOverflow = e => {
  if (e.currentTarget.href.match(/#$/)) e.preventDefault()
  document.querySelector(`.overflowMenu`).classList.toggle("openOverflow")
  document.querySelector(`body`).classList.toggle("no-overflow")
  document.querySelector(`.overflowMenuText`).classList.toggle("not-displayed")
  document
    .querySelector(`.overflowMenuCloseText`)
    .classList.toggle("not-displayed")
}

const MainMenuListItems = () => (
  <>
    {navLinks.map(({ title, path }) => (
      <MenuItem key={title}>
        <MenuLink to={path}>{title}</MenuLink>
      </MenuItem>
    ))}
  </>
)

const MenuOverflowButton = () => (
  <MenuItem style={{ zIndex: 1000 }}>
    <MenuLink to="#" onClick={toggleOverflow}>
      <MenuMenuText>
        <span className="overflowMenuCloseText not-displayed">Close</span>
        <span className="overflowMenuText">Menu</span>
      </MenuMenuText>
      <img src={hamburgerSVG} alt="Expand menu" />
    </MenuLink>
  </MenuItem>
)

const MenuOverflow = () => (
  <MenuOverflowWrapper className="overflowMenu">
    <Link to="/" onClick={toggleOverflow}>
      <Stamp overflow src={stampSVG} alt="Link to homepage" />
    </Link>
    <MenuOverflowList>
      {navLinks.map(({ title, path }) => (
        <MenuOverflowItem key={title}>
          <MenuOverflowLink to={path} onClick={toggleOverflow}>
            {title}
          </MenuOverflowLink>
        </MenuOverflowItem>
      ))}
    </MenuOverflowList>
  </MenuOverflowWrapper>
)

const Menubar = ({ props, children, isFront }) => {
  return (
    <MenubarWrapper>
      <StampLink to="/" isFront={isFront}>
        <Stamp src={stampSVG} alt="Link to homepage" />
      </StampLink>

      <MenuNav>
        <MenuList>
          <MainMenuListItems />
          <MenuOverflowButton className="overflowMenuButton" />
        </MenuList>
      </MenuNav>
      <MenuOverflow />
    </MenubarWrapper>
  )
}

const StampLink = styled(Link)`
  ${props => props.isFront && `visibility: hidden;`}
`

const Stamp = styled.img`
  z-index: 10;
  display: block;
  width: 150px;
  height: 150px;
  ${props => props.overflow && `width: 150px; height: 150px;`}
`

const MenuOverflowWrapper = styled.div`
  z-index: 10;
  display: flex;
  opacity: 0;
  transform: translateY(-100vh);
  transition: 0.5s all;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to top right,
    ${theme.c.wcag.navy} 0%,
    ${theme.c.wcag.navy} 100%
  );
  > * {
    padding: ${theme.margin};
  }
  &.openOverflow {
    opacity: 1;
    transform: translateY(0);
  }
`

const MenuOverflowList = styled.ul`
  list-style: none;
`

const MenuOverflowItem = styled.li`
  font: 200 ${theme.font.size.headingLarge} ${theme.font.fam.univers};
`
const MenuOverflowLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const MenubarWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: calc(100% - ${theme.unit * 8}px);
  padding: ${theme.margin} ${theme.gutter};
  justify-content: space-between;
`

const MenuNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 3; // to fix menu overflow bug in IE11
`

const MenuList = styled.ul`
  display: flex;
  list-style: none;
`

const MenuItem = styled.li`
  display: none;
  padding-left: ${theme.gutter};
  height: 37px;
  line-height: 37px;
  &:last-child {
    display: inline-block;
    a {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  @media ${theme.mq.menu} {
    & {
      display: inline-block;
    }
    &:last-child {
      display: none;
    }
  }
`

const MenuMenuText = styled.span`
  display: inline-block;
  padding-right: ${theme.unit}px;
  @media ${theme.mq.menu} {
    display: none;
  }
`

const MenuLink = styled(Link)`
  font-size: ${theme.font.size.menu};
  font: ${theme.font.size.menu} ${theme.font.fam.univers};
  text-decoration: none;
  color: #fff;
`

export default Menubar
