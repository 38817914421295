import React from "react"
import { Link as GatsbyLink } from "gatsby"

// Make a <Link> that handles external links gracefully
const Link = ({ children, to, activeClassName, ...other }) => {
  const external = /^http[s]?:\/\//.test(to)

  if (external || to === "#") {
    return (
      <a href={to} target="_blank" {...other}>
        {children}
      </a>
    )
  }
  return (
    <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
      {children}
    </GatsbyLink>
  )
}

export default Link
