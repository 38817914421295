import React from "react"
import styled from "styled-components"
import { theme } from "../components/css"
import { Button, ButtonSimple } from "../components/button"

const Youtube = ({ videoID, title, style }) => {
  return (
    <div>
      <VideoWrapper>
        <Video
          width="560"
          height="315"
          src={"https://www.youtube.com/embed/" + videoID}
          frameborder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          title={title}
          style={style}
        />
      </VideoWrapper>
    </div>
  )
}

export default Youtube

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin: ${theme.gutter} 0;
`
