import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Intro, Content, RibbonFooter } from "../components/pageElements"
import { Button } from "../components/button"
import Youtube from "../components/youtube"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { theme } from "../components/css"

class AlumniTemplate extends React.Component {
  render() {
    let alumni = this.props.data.contentfulFeaturedAlumni
    return (
      <Layout
        isAlumni
        headerImage={alumni.headerImage}
        pageTitle={alumni.fullName}
        pageSubTitle={alumni.graduatedDegree}
      >
        <SEO title={alumni.fullName} />
        <Intro>
          <Current>{alumni.about.about}</Current>
          {documentToReactComponents(alumni.intro.json)}
          {alumni.youtubeVideoId && (
            <Youtube
              videoID={alumni.youtubeVideoId}
              title={`${alumni.fullName} teaser video`}
            />
          )}
        </Intro>
        <Content>{documentToReactComponents(alumni.body.json)}</Content>
        <RibbonFooter>
          <h2>Join our Alumni in Prayer</h2>
          <p>
            Each of our distinguished alumni have identified seven areas of
            prayer and are asking that the FPU community join them.
          </p>
          <Button to="https://www.fresno.edu/sites/default/files/75th-digital-alumni-prayer-guide.pdf">Download guide</Button>
        </RibbonFooter>
      </Layout>
    )
  }
}

export default AlumniTemplate

const Current = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  padding: ${theme.margin};
  margin-bottom: ${theme.gutter};
`

export const alumniTemplateQuery = graphql`
  query AlumniByContentfulId($contentful_id: String!) {
    contentfulFeaturedAlumni(contentful_id: { eq: $contentful_id }) {
      id
      fullName
      graduatedDegree
      currentPosition
      youtubeVideoId
      intro {
        json
      }
      body {
        json
      }
      about {
        about
      }
      headerImage {
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
