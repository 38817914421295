import React from "react"
import styled from "styled-components"
import { theme } from "./css"
import Link from "./link"

const ButtonSimple = styled(Link)`
  position: relative;
  display: inline-block;
  width: calc(100% - 72px);
  height: 36px;
  padding: 0 16px;
  margin-bottom: ${theme.margin};
  background-color: ${props => props.color || theme.c.navy};
  font: 200 20px/36px ${theme.font.fam.univers};
  color: white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  :hover {
    filter: brightness(110%);
  }

  @media ${theme.mq.medium} {
    width: auto;
    margin-bottom: inherit;
  }
`

const Button = styled(ButtonSimple)`
  margin-left: 36px;
  ::before {
    position: absolute;
    display: block;
    top: 0;
    left: -36px;
    content: "";
    width: 36px;
    height: 36px;
    background: linear-gradient(to bottom right, ${theme.c.navy} 50%, ${theme.c.orange} 50%);
  }
`

export { Button, ButtonSimple }
