import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { theme, GlobalStyle } from "../components/css"

import Header from "./header"
import HeaderAlumni from "./headerAlumni"

import fpuLogo from "../images/logo-fpu-verticle.svg"

const Layout = ({ headerImage, pageTitle, children, isFront, isAlumni }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />
        {!isAlumni ? (
          <Header
            siteTitle={data.site.siteMetadata.title}
            pageTitle={pageTitle}
            headerImage={headerImage}
            isFront={isFront}
          />
        ) : (
          <HeaderAlumni
            siteTitle={data.site.siteMetadata.title}
            pageTitle={pageTitle}
            headerImage={headerImage}
          />
        )}
        <main>{children}</main>
        <FooterGlobal>
          <div>Fresno Pacific University &copy; {new Date().getFullYear()}</div>
          <div>
            <img src={fpuLogo} alt="Fresno Pacific University" />
          </div>
        </FooterGlobal>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
}

const FooterGlobal = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: calc(100% - ${theme.unit * 8}px);
  margin-top: ${theme.margin};
  padding: 0 ${theme.gutter} ${theme.gutter};
  font-size: 14px;
  color: ${theme.c.utility.textLight};
  div:first-child {
    order: 2;
  }
  div:last-child {
    margin-bottom: ${theme.margin};
    order: 1;
  }
  @media ${theme.mq.medium} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    // margin: -60px auto 0;
    margin: 0 auto;
    div:first-child {
      order: 1;
    }
    div:last-child {
      margin-bottom: 0;
      order: 2;
    }
  }
`

export default Layout
