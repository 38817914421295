/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// detect apng support
const apngSupport = () => {
  "use strict"
  var apngTest = new Image(),
    ctx = document.createElement("canvas").getContext("2d")
  apngTest.onload = function() {
    ctx.drawImage(apngTest, 0, 0)
    if (ctx.getImageData(0, 0, 1, 1).data[3] === 0) {
      document.querySelector("html").classList.add("apng")
    } else {
      document.querySelector("html").classList.add("no-apng")
    }
  }
  apngTest.src =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACGFjVEwAAAABAAAAAcMq2TYAAAANSURBVAiZY2BgYPgPAAEEAQB9ssjfAAAAGmZjVEwAAAAAAAAAAQAAAAEAAAAAAAAAAAD6A+gBAbNU+2sAAAARZmRBVAAAAAEImWNgYGBgAAAABQAB6MzFdgAAAABJRU5ErkJggg=="
}

exports.onInitialClientRender = () => {
  apngSupport()
  if (typeof instgrm !== "undefined") instgrm.Embeds.process()
  if (typeof twttr !== "undefined") twttr.widgets.load()
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname === `/stories` || location.pathname === `/stories/`) {
    if (typeof instgrm !== "undefined") instgrm.Embeds.process()
    if (typeof twttr !== "undefined") twttr.widgets.load()
  }
}
