import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Intro, Content, RibbonFooter } from "../components/pageElements"
import { Button } from "../components/button"
import styled from "styled-components"
import { theme } from "../components/css"
import Link from "../components/link"

import headerImage from "../images/header-stories.jpg"
const pageTitle = "News & Stories"

import iconFacebook from "../images/icon-facebook.svg"
import iconTwitter from "../images/icon-twitter.svg"
import iconInstagram from "../images/icon-instagram.svg"
import iconWordpress from "../images/icon-wordpress.svg"
import iconTumblr from "../images/icon-tumblr.svg"

const Story = ({ story, type }) => {
  if (type === `News`) {
    return <StoryGeneric story={story} />
  // } else if (type === `Twitter` || type === `Instagram`) {
  //   return (
  //     <Article dangerouslySetInnerHTML={{ __html: story.fields.oembedCode }} />
  //   )
  } else {
    return false
  }
}

const StoryGeneric = ({ story }) => {
  return (
    <Article static>
      {story.image && (
        <a href={story.link}>
          <Img fluid={story.image.localFile.childImageSharp.fluid} />
        </a>
      )}
      <ArticleTextWrapper>
        {story.title && (
          <ArticleTitle>
            <Link to={story.link}>{story.title}</Link>
          </ArticleTitle>
        )}
        {story.excerpt.excerpt && <p>{story.excerpt.excerpt}</p>}
        {story.fields.storySource && (
          <ArticleSourceLink to={story.link}>
            {story.fields.storySource}
          </ArticleSourceLink>
        )}
      </ArticleTextWrapper>
    </Article>
  )
}

const StoriesPage = ({ data }) => {
  const stories = data.allContentfulStoryItem.edges
  return (
    <Layout pageTitle={pageTitle} headerImage={headerImage}>
      <SEO title={pageTitle} />
      <Intro>
        <h2>Keep up with what’s happening</h2>
        <p>
        Here you'll find a collection of news articles and social media posts involving FPU's history and celebratory updates from the events for the 80th Anniversary. We want to share your FPU story! Use the hashtag #FPU80 and share photos, favorite moments and unforgettable experiences from your time throughout these past 80 years. Make sure to include a caption so we can repost your photos.
        </p>
      </Intro>
      <Content>
        <StoryWrapper>
          {stories.map(({ node }) => (
            <Story
              key={node.contentful_id}
              type={node.fields.storyType}
              story={node}
            />
          ))}
        </StoryWrapper>
      </Content>
      <RibbonFooter>
        <h2>Be a part of our future</h2>
        <p>
        Your generous gifts are essential to the success of Fresno Pacific University, helping to maintain and improve the excellent academic, ethical and spiritual education we offer. With your partnership, we can continue providing transformative educational experiences that shape the leaders of tomorrow.
        </p>
        <Button to="https://giving.fresno.edu">Give now</Button>
      </RibbonFooter>
    </Layout>
  )
}

export default StoriesPage

const SocialButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${theme.mq.medium} {
    justify-content: flex-start;
  }
`

const SocialButton = styled(Link)`
  display: block;
  width: 36px;
  height: 36px;
  margin: 0 0 0 ${theme.unit}px;
  img {
    display: block;
    width: 36px;
    height: 36px;
  }
`

const StoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  @media ${theme.mq.medium} {
    max-width: 75%;
  }
`
const Article = styled.div`
  max-width: 100%;
  margin-bottom: ${theme.gutter};
  .twitter-tweet {
    margin-top: 0 !important; // override embedded styles
    margin-bottom: 0 !important;
    padding: 16px;
    background: white;
    border: 1px solid lightgray;
    border-radius: 3px;
    &.twitter-tweet-rendered {
      padding: inherit;
      background: inherit;
      border: inherit;
      border-radius: inherit;
    }
  }
  @media ${theme.mq.medium} {
    min-width: 500px;
  }
  iframe {
    min-width: 100% !important; // to override embeded styles
  }
  ${props =>
    props.static &&
    `
    border: 1px solid lightgray;
    border-radius: 3px;
    background: white;
  `}
`

const ArticleTitle = styled.h3`
  padding-left: 0;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &::before {
    display: none;
  }
`

const ArticleSourceLink = styled(Link)`
  font-size: 14px;
  &:visited {
    color: ${theme.c.navy};
  }
`

const ArticleTextWrapper = styled.div`
  padding: ${theme.margin};
`

export const storiesQuery = graphql`
  query storiesQuery {
    allContentfulStoryItem(sort: { fields: date, order: DESC }) {
      edges {
        node {
          date
          link
          fields {
            oembedCode
            storyType
            storySource
          }
          title
          contentful_id
          excerpt {
            excerpt
          }
          image {
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 1400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
