import React from "react"
import { createGlobalStyle } from "styled-components"

const theme = {
  unit: 8,
  margin: () => {
    return `${theme.unit * 2}px`
  },
  gutter: () => {
    return `${theme.unit * 4}px`
  },
  row: "1000px",
  c: {
    navy: "#00205b",
    orange: "#ff671f",
    maroon: "#910048",
    briteblue: "#00a9ce",
    frost: "#59cbe8",
    green: "#48a23f",
    mint: "#a1d884",
    yellow: "#ffbf3f",
    grey: "#b1b3b3",
    gold: "#c5b783",
    rgb: {
      navy: "0, 32, 91",
      orange: "255, 103, 31",
      maroon: "145, 0, 72",
      briteblue: "0, 169, 206",
      frost: "89, 203, 232",
      green: "72, 162, 63",
      mint: "161, 216, 132",
      grey: "177, 179, 179",
      gold: "197, 183, 131",
    },
    wcag: {
      briteblue: "#00819d",
      green: "#3c8634",
      gold: "#7f744a",
    },
    utility: {
      text: "#222",
      textLight: "#737373",
      link: "#003daf",
      background: "#f9f9f9",
    },
  },
  font: {
    size: {
      body: "20px",
      menu: "18px",
      title: "72px",
      headingLarge: "48px",
      headingMedium: "36px",
      headingSmall: "28px",
    },
    fam: {
      sanchez: "'Sanchez W03', Rockwell, 'Lucidia Bright', slab-serif, serif",
      univers: "'Univers W01', Helvetica, Arial, sans-serif",
    },
  },
  mq: {
    xsmall: "only screen and (max-width: 370px)",
    small: "only screen and (min-width: 371px)",
    smallOnly: "only screen and (min-width: 371px) and (max-width: 859px)",
    medium: "only screen and (min-width: 860px)",
    mediumOnly: "only screen and (min-width: 860px) and (max-width: 1024px)",
    large: "only screen and (min-width: 1025px)",
    largeOnly: "only screen and (min-width: 1025px) and (max-width: 1919px)",
    xlarge: "only screen and (min-width: 1920px)",
    menu: "only screen and (min-width: 660px)",
    ie: "all and (-ms-high-contrast: none), (-ms-high-contrast: active)",
  },
}

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  body {
    background-color: #f9f9f9;
    font-family: ${theme.font.fam.univers};
    font-size: 18px;
    color: ${theme.c.utility.text};
  }

  p {
    margin-bottom: 16px;
    line-height: 25px;
  }

  h1, h2, h3, h4 {
    margin-bottom: ${theme.unit}px;
    font-family: ${theme.font.fam.univers};
    font-weight: 200;
    text-transform: uppercase;
    color: ${theme.c.navy};
  }

  h2 {
    font-size: ${theme.font.size.headingLarge};
    line-height: 54px;
  }

  h3 {
    font-size: ${theme.font.size.headingMedium};
    line-height: 40px;
  }

  h4 {
    font-size: ${theme.font.size.headingSmall};
    line-height: 32px;
  }

  a:not([class]) {
    color: ${theme.c.navy};
    &:visited {
      color: ${theme.c.navy}
    }
  }

  .not-displayed {
    display: none;
  }

  .no-overflow {
    overflow: hidden;
  }
`

export { theme, GlobalStyle }
