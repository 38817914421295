import React from "react"
import styled, { css } from "styled-components"
import { theme } from "./css"

// Images
import angleBottomSVG from "../images/mask-angle-bottom.svg"
import angleTopSVG from "../images/mask-angle-top.svg"
import ribbonBg from "../images/main-campus-archive-mb-seminary00041.jpg"
import introLinework from "../images/linework-intro.svg"

const Intro = ({ children }) => {
  return (
    <IntroWrapper>
      <Shim>{children}</Shim>
      <AngleBottom />
    </IntroWrapper>
  )
}

const headingTriangle = ({ color, size, offset }) => {
  return css`
    position: relative;
    :not([class])::before {
      position: absolute;
      bottom: ${offset ? offset : "8px"};
      left: 0;
      display: block;
      width: 0;
      height: 0;
      content: "";
      border-color: transparent transparent ${color ? color : theme.c.orange}
        transparent;
      border-width: 0 0 ${size ? `${size} ${size}` : "36px 36px"};
      border-style: solid;
    }
  `
}

const headingStyles = css`
  h2:not([class]) {
    font: 200 ${theme.font.size.headingLarge} / 52px ${theme.font.fam.univers};
    padding-left: 45px;
    ${headingTriangle({ color: theme.c.orange })};
  }
  h3:not([class]) {
    font-size: ${theme.font.size.headingMedium};
    padding-left: 35px;
    ${headingTriangle({ color: theme.c.orange, size: "28px" })};
  }
`

const IntroWrapper = styled.div`
  position: relative;
  padding-top: ${theme.unit * 8}px;
  background: linear-gradient(
      185.8deg,
      rgba(89, 203, 232, 0.63) 91.03%,
      rgba(89, 203, 232, 0.63) 91.03% 
    ),
    #ffffff;
  ${headingStyles};
  ::after {
    position: absolute;
    left: 0;
    bottom: -37px;
    content: "";
    display: block;
    width: 100%;
    height: 139px;
    background: transparent url(${introLinework}) 0 0 no-repeat;
    background-position: 0 0;
  }
`

const Content = ({ children }) => {
  return (
    <ContentWrapper>
      <Shim>{children}</Shim>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  padding-top: ${theme.unit * 8}px;
  padding-bottom: ${theme.gutter};
  ${headingStyles};
  ul,
  ol {
    margin-left: ${theme.margin};
    li {
      margin-bottom: ${theme.unit}px;
    }
    p {
      margin-bottom: 0;
    }
  }
`

const Shim = styled.div`
  width: calc(100% - ${theme.unit * 8}px);
  margin-left: auto;
  margin-right: auto;
  padding-left: ${theme.gutter};
  padding-right: ${theme.gutter};
  max-width: ${theme.row};
  @media ${theme.mq.medium} {
    width: calc(100% - ${theme.gutter});
    padding-left: ${theme.margin};
    padding-right: ${theme.margin};
  }
`

const AngleBottom = () => {
  return (
    <div>
      <img
        style={{ display: "block", width: "100%" }}
        src={angleBottomSVG}
        alt=""
      />
    </div>
  )
}

const AngleTop = () => {
  return (
    <div>
      <img
        style={{ display: "block", width: "100%" }}
        src={angleTopSVG}
        alt=""
      />
    </div>
  )
}

const RibbonFooter = ({ children }) => {
  return (
    <RibbonFooterWrapper>
      <AngleTop />
      <RibbonFooterShim>
        <RibbonFooterContent>{children}</RibbonFooterContent>
      </RibbonFooterShim>
      <AngleBottom />
    </RibbonFooterWrapper>
  )
}

const RibbonFooterShim = styled(Shim)`
  padding-top: 64px;
  padding-bottom: 48px;
  @media ${theme.mq.medium} {
    padding-bottom: 32px;
  }
`

const RibbonFooterWrapper = styled.footer`
  background-color: ${theme.c.navy};
  background: linear-gradient(
      to bottom right,
      rgba(0, 32, 91, 0.9) 40%,
      rgba(0, 169, 206, 0.63) 110%
    ),
    url(${ribbonBg});

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  @media ${theme.mq.medium} {
    margin-bottom: -60px;
    background-image: linear-gradient(
        85.85deg,
        rgba(0, 32, 91, 0.9) 21.15%,
        rgba(0, 178, 219, 0.63) 91.03%
      ),
      url(${ribbonBg});
  }
  h2 {
    font-size: ${theme.font.size.headingMedium};
    line-height: 38px;
    letter-spacing: 0.03rem;
    padding-left: 37px;
    color: white;
    ${headingTriangle({
      color: theme.c.orange,
      size: "28px",
      offset: "5px",
    })};
  }
`

const RibbonFooterContent = styled.div`
  width: 100%;
  @media ${theme.mq.medium} {
    max-width: 50%;
  }
`

export { Intro, Content, Shim, RibbonFooter }
