import React from "react"

import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Intro, Content, RibbonFooter } from "../components/pageElements"
import { theme } from "../components/css"
import Events from "../components/events"
import Sponsors from "../components/sponsors"
import Youtube from "../components/youtube"

import headerImage from "../images/header-home.jpg"
import photoJoe from "../images/photo-president.jpg"

const IndexPage = () => (
  <Layout isFront headerImage={headerImage}>
    <SEO title="Home" />
    <Intro>
      <h2>Faith | Wisdom | Service</h2>
      <p>
      Fresno Pacific University proudly celebrates its 80th anniversary, marking eight decades of transformative impact and endless possibilities. This significant milestone highlights the university’s unwavering commitment to Christ-centered education and its profound legacy of empowering students. Through dedication to academic excellence and spiritual growth, Fresno Pacific continues to nurture leaders who are equipped to make a difference in the world. As we honor our past, we look forward to a future filled with even greater achievements and opportunities, staying true to our mission of shaping lives for service and leadership.
      </p>
      <Quote>
        <QuoteImage>
          <img src="https://www.fresno.edu/document/1575/" alt="Photo of André Stephens, Ph.D." />
        </QuoteImage>
        <QuoteAttr>
          <QuoteAttrName>André Stephens, Ph.D.</QuoteAttrName>
          President
        </QuoteAttr>
      </Quote>
    </Intro>

    <Content>
      <Events />
      <Youtube
        videoID="i2OZwZuyUWs"
        title="A Brief History of Fresno Pacific"
        style={{ marginTop: "50px" }}
      />
      {/* <Sponsors /> */}
    </Content>
    <RibbonFooter>
      <Verse>
        But Jesus looked at them and said, "For mortals it is impossible, but
        for God all things are POSSIBLE."
        <VerseLocation>Matthew 19:26 NRSV</VerseLocation>
      </Verse>
    </RibbonFooter>
  </Layout>
)

const Quote = styled.div`
  display: flex;
  margin-bottom: ${theme.margin};
  align-items: center;
`
const QuoteAttr = styled.div`
  margin-left: ${theme.margin};
`

const QuoteAttrName = styled.span`
  display: block;
  font: 200 24px ${theme.font.fam.univers};
  text-transform: uppercase;
  color: ${theme.c.navy};
`

const QuoteImage = styled.div`
  position: relative;
  display: block;
  border-radius: 1000px;
  border: ${theme.unit}px solid transparent;
  z-index: 0;
  max-width: 83px;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    margin: -${theme.unit}px;
    border-radius: inherit;
    background: linear-gradient(
      to bottom right,
      ${theme.c.orange} 0%,
      ${theme.c.orange} 60%
    );
  }
  img {
    display: block;
    border-radius: 1000px;
    width: 100%;
    height: auto;
  }
`

const Verse = styled.div`
  font-size: ${theme.font.size.headingSmall};
  line-height: 34px;
  font-family: ${theme.font.fam.sanchez};
`

const VerseLocation = styled.p`
  margin-top: ${theme.margin};
  margin-bottom: 0;
  font-size: ${theme.font.size.body};
  font-weight: bold;
  color: ${theme.c.orange};
`

export default IndexPage
