import React from "react"
import Link from "./link"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { theme } from "./css"
import Menubar from "./menu.js"

import defaultHeaderImage from "../images/header-events.jpg"
import headerTriangle from "../images/header-alumni-triangle.png"
import headerLinework from "../images/header-alumni-linework.svg"

const HeaderAlumni = ({ headerImage, siteTitle, pageTitle }) => {
  return (
    <HeaderAlumniWrapper
      headerImage={
        (headerImage && headerImage.localFile.childImageSharp.fluid.src) ||
        defaultHeaderImage
      }
    >
      <Menubar>
        <h1>
          <Link to="/">{siteTitle}</Link>
        </h1>
      </Menubar>
      {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
    </HeaderAlumniWrapper>
  )
}

HeaderAlumni.propTypes = {
  siteTitle: PropTypes.string,
  pageTitle: PropTypes.string,
  pageSubTitle: PropTypes.string,
}

HeaderAlumni.defaultProps = {
  siteTitle: ``,
  pageTitle: ``,
  pageSubTitle: ``,
}

// Styled components
const HeaderAlumniWrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  height: 100vh;
  // background-size: auto, 100vw 70vh, cover, cover, cover;
  background-size: auto, auto, cover, cover, cover;
  background-repeat: no-repeat;
  background-position: bottom -100px right -460px, bottom right, center, center,
    right -250px center;
  background-image: ${props => `url(${headerLinework})`},
    ${props => `url(${headerTriangle})`},
    linear-gradient(
      195.64deg,
      rgba(0, 169, 206, 0.8974) -2.33%,
      rgba(0, 82, 135, 0) 39.2%
    ),
    linear-gradient(to top right, ${theme.c.navy} 10%, transparent 30%),
    ${props => props.headerImage && `url(${props.headerImage})`};
  @media ${theme.mq.medium} {
    background-size: auto, auto, cover, cover, cover;
    background-position: bottom left 265px, bottom left, center, center, center;
  }
`

const PageTitle = styled.h1`
  display: block;
  max-width: ${theme.row};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${theme.gutter};
  padding-left: ${theme.gutter};
  padding-right: ${theme.gutter};
  // margin-left: 10vw;
  font-size: 56px;
  line-height: 48px;
  color: white;
  @media ${theme.mq.medium} {
    padding-left: ${theme.margin};
    padding-right: ${theme.margin};
  }
`

export default HeaderAlumni
