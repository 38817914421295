import React from "react"
import Link from "./link"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { theme } from "./css"
import Menubar from "./menu.js"

import stampGIF from "../images/stamp-animation.gif"
import stampPNG from "../images/stamp-animation.png"

const Header = ({ headerImage, siteTitle, pageTitle, isFront }) => {
  return (
    <HeaderGlobal headerImage={headerImage} isFront={isFront}>
      <Menubar isFront={isFront}>
        <h1>
          <Link to="/">{siteTitle}</Link>
        </h1>
      </Menubar>
      {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
      {isFront && (
        <StampAnimationGIF
          src={stampGIF}
          alt="Fresno Pacific University | 80 years | 1944 - 2024"
        />
      )}
      {isFront && (
        <StampAnimationPNG
          src={stampPNG}
          alt="Fresno Pacific University | 80 years | 1944 - 2024"
          style={{ width: 300, minWidth: 200, maxWidth: 800 }}
        />
      )}
    </HeaderGlobal>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  isFront: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  isFront: false,
}

// Styled components
const PageTitle = styled.h1`
  padding-left: ${theme.margin};
  padding-right: ${theme.margin};
  font: 200 ${theme.font.size.title} ${theme.font.fam.univers};
  text-align: center;
  text-transform: uppercase;
  color: white;
`

const StampAnimation = styled.img`
  display: none;
  width: 50%;
  min-width: 700px;
  max-width: 800px;
  background-color: transparent;
`
const StampAnimationPNG = styled(StampAnimation)`
  .apng & {
    display: inline-block;
  }
`

const StampAnimationGIF = styled(StampAnimation)`
  .no-apng & {
    display: inline-block;
  }
`

const HeaderGlobal = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 90vh;
  background-size: cover;
  background-position: center;

  @media ${theme.mq.medium} {
    height: ${props => (props.isFront && `90vh`) || `60vh`};
    background-attachment: fixed;
    background-position: center,
      ${props => !props.isFront && `center, `} center
        ${props => props.headerImage && `, center -100px`};
  }

  background-image: linear-gradient(
      195.64deg,
      rgba(0, 169, 206, 0.8974) -2.33%,
      rgba(0, 82, 135, 0) 39.2%
    ),
    ${props =>
        !props.isFront &&
        `linear-gradient(17.85deg, rgba(197, 183, 131, 0.5) 25.6%, rgba(197, 183, 131, 0.135) 68.35%) ,`}
      linear-gradient(
        319.67deg,
        rgba(0, 169, 206, 0.5) 34.28%,
        rgba(0, 32, 91, 0.5) 87.6%
      )
      ${props => props.headerImage && `, url(${props.headerImage})`};
`
export default Header