import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Events from "../components/events"
import { Intro, Content, RibbonFooter } from "../components/pageElements"
import { Button } from "../components/button"

import headerImage from "../images/header-events.jpg"
const pageTitle = "Anniversary Events"

const EventsPage = () => (
  <Layout pageTitle={pageTitle} headerImage={headerImage}>
    <SEO title={pageTitle} />
    <Content>
      <Events />
    </Content>
    <RibbonFooter>
      <h2>Be a part of our future</h2>
      <p>
      Your generous gifts are essential to the success of Fresno Pacific University, helping to maintain and improve the excellent academic, ethical and spiritual education we offer. With your partnership, we can continue providing transformative educational experiences that shape the leaders of tomorrow.
      </p>
      <Button to="https://giving.fresno.edu">Give now</Button>
    </RibbonFooter>
  </Layout>
)

export default EventsPage
