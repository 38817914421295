import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Intro, Content, RibbonFooter } from "../components/pageElements"
import Img from "gatsby-image"
// import Link from "../components/link"
import styled from "styled-components"
import SEO from "../components/seo"
import { Button } from "../components/button"
import { theme } from "../components/css"

import headerImage from "../images/header-timeline.jpg"
const pageTitle = "Timeline"

const Timeline = ({ data }) => {
  const timeline = data.allContentfulTimelineItem.group
  return (
    <Layout pageTitle={pageTitle} headerImage={headerImage}>
      <SEO title={pageTitle} />
      <Intro>
        <h2>From PBI to FPU</h2>
        <p>
          What began as Pacific Bible Institute—a fledgling Bible college for
          the Mennonite Brethren founded during the tumult of World War II—would
          grow to become one of the nation's most diverse and inclusive
          Christian universities.
        </p>
        <p>
          Fresno Pacific University is an institution of faith, grit and
          creativity. Despite numerous challenges over more than eight decades,
          FPU has managed to thrive in a region plagued by low degree
          attainment, brain drain, limited resources and limited access.
          Together, we have launched more than 26,000 alumni into service and
          leadership in education, business, health care, human services,
          ministry and more.
        </p>
      </Intro>
      <Content>
        <TimelineWrapper>
          {timeline.length > 0 &&
            timeline.map(({ edges }) => {
              return (
                <TimelineSet>
                  <DateBubble>
                    <DateBubbleTitle>
                      {edges[0].node.fields.Year}
                    </DateBubbleTitle>
                  </DateBubble>
                  {edges.map(({ node }) => {
                    return (
                      <TimelineRow key={node.contentful_id}>
                        <TimelinePhotoWrapper>
                          <TimelinePhoto
                            fluid={node.image.localFile.childImageSharp.fluid}
                            alt={node.image.title || `Photo`}
                          />
                        </TimelinePhotoWrapper>
                        <TimelineDescription>
                          <p>
                            <TimelineDate>
                              {node.fields.DisplayDate}
                            </TimelineDate>
                            <br />
                            {node.description.description}
                          </p>
                          <TimelineDateline />
                        </TimelineDescription>
                      </TimelineRow>
                    )
                  })}
                </TimelineSet>
              )
            })}
        </TimelineWrapper>
      </Content>
      <RibbonFooter>
        <h2>Be a part of our future</h2>
        <p>
        Your generous gifts are essential to the success of Fresno Pacific University, helping to maintain and improve the excellent academic, ethical and spiritual education we offer. With your partnership, we can continue providing transformative educational experiences that shape the leaders of tomorrow.
        </p>
        <Button to="https://giving.fresno.edu">Give now</Button>
      </RibbonFooter>
    </Layout>
  )
}

export default Timeline

import timelineCenterlineImage from "../images/timeline-centerline.png"

const TimelineWrapper = styled.div`
  background-image: url(${timelineCenterlineImage});
  background-repeat: repeat-y;
  background-position: center top;
  @media ${theme.mq.medium} {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`

const TimelineSet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TimelineRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${theme.gutter};
  @media ${theme.mq.medium} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const TimelinePhotoWrapper = styled.div`
  position: relative;
  display: block;
  height: auto;
  width: 100%;
  @media ${theme.mq.medium} {
    flex: 1 1 calc(50% - ${theme.gutter});
    margin-right: ${theme.gutter};
    width: calc(50% - ${theme.gutter});
  }
  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    z-index: 1;
  }
  ::before {
    top: -3px;
    left: -3px;
    border-width: 40px 40px 0 0;
    border-style: solid;
    border-color: ${theme.c.navy} transparent transparent;
  }
  ::after {
    bottom: -3px;
    right: -3px;
    border-width: 40px 40px 0 0;
    border-style: solid;
    border-color: transparent ${theme.c.navy} transparent;
  }
`

const TimelinePhoto = styled(Img)``

const TimelineDescription = styled.div`
  position: relative;
  padding: ${theme.gutter};
  background: 
    linear-gradient(
      127deg,
      rgba(0, 32, 91, 0.9) 5.34%,
      rgba(0, 32, 91, 0.9) 5.34%
    ),
    linear-gradient(
      180deg, 
      #ffffff 0%, 
      rgba(255, 255, 255, 0) 100%),
      #fff;
  color: white;
  @media ${theme.mq.medium} {
    flex: 1 1 calc(50% - ${theme.unit * 12}px);
    width: calc(50% - ${theme.unit * 12}px);
    margin-top: ${theme.unit * 12}px;
    margin-left: ${theme.gutter};
  }
  p {
    margin-bottom: 0;
  }
`

const TimelineDateline = styled.span`
  display: none;
  @media ${theme.mq.medium} {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: -${theme.gutter};
    right: 0;
    display: block;
    width: ${theme.gutter};
    height: 4px;
    border-top: 4px solid ${theme.c.navy};
    ::before {
      position: absolute;
      top: -14px;
      left: -10px;
      content: "";
      width: 16px;
      height: 16px;
      background-color: ${theme.c.utility.background};
      border: 4px solid ${theme.c.navy};
      border-radius: 1000px;
    }
  }
`

const TimelineDate = styled.strong`
  display: inline-block;
  margin-bottom: ${theme.unit}px;
  font: 200 ${theme.font.size.body} ${theme.font.fam.univers};
  letter-spacing: 0.03rem;
  text-transform: uppercase;
`

const DateBubble = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  padding: 10px;
  margin: ${theme.gutter} 0 ${theme.gutter};
  background: linear-gradient(
    to top left,
    ${theme.c.orange} 0%,
    ${theme.c.orange} 60%
  );
  :first-child {
    margin-top: 0;
  }
  border-radius: 1000px;
  text-align: center;
`

const DateBubbleTitle = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 1000px;
  font: 200 56px ${theme.font.fam.univers};
  color: ${theme.c.navy};
  line-height: 110px;
  text-align: center;
`

export const timelineQuery = graphql`
  query TimelineQuery {
    allContentfulTimelineItem(sort: { fields: date }) {
      group(field: fields___Year) {
        edges {
          node {
            fields {
              Year
              DisplayDate
            }
            contentful_id
            date
            image {
              id
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            description {
              description
            }
          }
        }
      }
    }
  }
`
