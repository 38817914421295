import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Content } from "../components/pageElements"
import Link from "../components/link"

const pageTitle = "Page Not found"
import headerImage from "../images/header-404.jpg"

const NotFoundPage = () => (
  <Layout pageTitle={pageTitle} headerImage={headerImage}>
    <Content>
      <h2>Sorry about that</h2>
      <p>
        We couldn't find the page you are looking for.
        <Link to="/">Go to the homepage</Link>.
      </p>
    </Content>
  </Layout>
)

export default NotFoundPage
