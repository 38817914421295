import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { theme } from "../components/css"

const Sponsors = () => (
  <StaticQuery
    query={graphql`
      query SponsorQuery {
        allContentfulSponsor(sort: { fields: name }) {
          edges {
            node {
              id
              name
              link
              sponsorLevel
              logo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const sponsorData = data.allContentfulSponsor.edges

      return (
        <div style={{ margin: `${theme.unit * 12}px 0 ${theme.unit * 8}px` }}>
          <SponsorGroup sponsors={sponsorData} group="Legacy" />
          <SponsorGroup sponsors={sponsorData} group="Diamond" />
          <SponsorGroup sponsors={sponsorData} group="Silver" />
        </div>
      )
    }}
  />
)

const SponsorGroup = ({ sponsors, group }) => {
  const sponsorList = sponsors.filter(
    sponsor => sponsor.node.sponsorLevel === group
  )
  if (sponsorList.length > 0) {
    return (
      <>
        <SponsorsTitle>{group} Sponsors</SponsorsTitle>
        <SponsorsGrid>
          {sponsorList.map(({ node }) => (
            <a href={node.link} key={node.id}>
              <Sponsor key={node.name}>
                <SponsorImg
                  fluid={node.logo.localFile.childImageSharp.fluid}
                  alt={node.name}
                />
              </Sponsor>
            </a>
          ))}
        </SponsorsGrid>
      </>
    )
  } else {
    return <></>
  }
}

const SponsorsTitle = styled.h2`
  display: block;
  margin-bottom: ${theme.gutter};
  border-bottom: 1px solid rgb(${theme.c.rgb.grey}, 0.5);
  font-size: ${theme.font.size.headingSmall};
  text-align: center;
  @media ${theme.mq.medium} {
    text-align: left;
  }
`

const SponsorsGrid = styled.div`
  display: block;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${theme.gutter};
  @media ${theme.mq.medium} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${theme.margin};
    grid-row-gap: ${theme.margin};
    max-width: none;
  }
`

const Sponsor = styled.div`
  display: flex;
  align-items: center;
  margin: ${theme.gutter} 0;
  @media ${theme.mq.medium} {
    margin: 0;
  }
`

const SponsorImg = styled(Img)`
  flex: 1;
  width: 100%;
`

export default Sponsors
